<template>
  <v-card flat class="fill-height d-flex flex-column">
    <v-card-title class="pa-0">
      <v-tabs
        background-color="grey lighten-4"
        :disabled="table.loading || processing"
        mandatory
        slider-color="primary"
        v-model="tab"
        height="56"
      >
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#expense">
          <v-badge color="green" :value="table.expenses.length" :content="table.expenses.length">
            <v-icon size="18" left>tw-hr-expenses</v-icon>
            {{ $t(`dashboard.request_type.expense`) }}
          </v-badge>
        </v-tab>
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#imprest">
          <v-badge color="green" :value="table.imprests.length" :content="table.imprests.length">
            <v-icon size="18" left>tw-hr-cost</v-icon>
            {{ $t(`dashboard.request_type.imprest`) }}
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="pa-0 flex-grow-1">
      <v-tabs-items v-model="tab" mandatory class="fill-height">
        <v-tab-item value="expense" class="fill-height">
          <template v-if="table.loading">
            <v-card class="elevation-0 fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate size="60" color="grey lighten-3" />
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="table.expenses.length"
              class="request-table"
              :items="table.expenses"
              :loading="table.loading"
              :no-data-text="table.loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td>
                    <employee-profile :employee="row.item.expense.employee" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ row.item.expense.timestamp | formattedDateTimeMinute }} -
                        {{ row.item.expense.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle class="caption">
                        {{ row.item.expense.type.name }} -
                        <b>{{ $helpers.getConvertCurrency(row.item.expense.requested) }}</b>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td class="text-no-wrap text-right">
                    <template v-if="isApprover(row.item)">
                      <v-btn
                        :loading="processing && row.item.id === processedId && !isApproveProcess"
                        :disabled="processing && row.item.id !== processedId"
                        color="primary"
                        class="mr-2"
                        x-small
                        depressed
                        fab
                        @click="onClickExpenseApproval(row.item, false)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="processing && row.item.id === processedId && isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || isApproveProcess)"
                        color="success"
                        x-small
                        depressed
                        fab
                        @click="onClickExpenseApproval(row.item, true)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card class="elevation-0 fill-height d-flex justify-center align-center">
                {{ $t("dashboard.messages.no_data_expenses") }}
              </v-card>
            </template>
          </template>
        </v-tab-item>
        <v-tab-item value="imprest" class="fill-height">
          <template v-if="table.loading">
            <v-card class="elevation-0 fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate size="60" color="grey lighten-3" />
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="table.imprests.length"
              class="request-table"
              :items="table.imprests"
              :loading="table.loading"
              :no-data-text="table.loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td>
                    <employee-profile :employee="row.item.imprest.employee" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title>{{ row.item.imprest.period | formattedDate }}</v-list-item-title>
                      <v-list-item-subtitle class="caption"
                        ><b>{{ $helpers.getConvertCurrency(row.item.imprest.requested) }}</b></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </td>
                  <td class="text-no-wrap text-right">
                    <template v-if="isApprover(row.item)">
                      <v-btn
                        :loading="processing && row.item.id === processedId && !isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || isApproveProcess)"
                        color="primary"
                        class="mr-2"
                        x-small
                        depressed
                        fab
                        @click="onClickImprestApproval(row.item, false)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="processing && row.item.id === processedId && isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || !isApproveProcess)"
                        color="success"
                        x-small
                        depressed
                        fab
                        @click="onClickImprestApproval(row.item, true)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card class="elevation-0 fill-height d-flex justify-center align-center">
                {{ $t("dashboard.messages.no_data_imprests") }}
              </v-card>
            </template>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
  import { DASHBOARD_EXPENSE_IMPREST } from "../query";

  export default {
    name: "requests",
    data: vm => ({
      tab: "expense",
      pendingRequestCount: 0,
      processedId: null,
      isApproveProcess: false,
      processing: false,
      table: {
        criteria: {
          manager: null,
          beginDate: vm.$moment().format("YYYY-MM-DD"),
          endDate: vm.$moment().format("YYYY-MM-DD")
        },
        loading: false,
        expenses: [],
        imprests: [],
        headers: [],
        totalItems: 0,
        pagination: {
          rowsPerPage: 5
        }
      }
    }),

    methods: {
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: DASHBOARD_EXPENSE_IMPREST,
            variables: this.table.criteria
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              const { expenseRequests, imprestRequests } = data.dashboard;
              this.table.expenses = expenseRequests.filter(
                request => request.approver.id === this.$store.state.auth.user.id
              );
              this.table.imprests = imprestRequests.filter(
                request => request.approver.id === this.$store.state.auth.user.id
              );
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.table.loading = false;
          });
      },
      isApprover(item) {
        if (item.approver) {
          return item.approver.id === this.$store.state.auth.user.id;
        } else {
          return false;
        }
      },
      onClickExpenseApproval({ id, expense }, status) {
        if (id) {
          this.processedId = id;
          this.isApproveProcess = status;
          const payload = {
            approved: status,
            note: "",
            approvedAmount: expense.requested.amount
          };
          this.processing = true;
          this.$api.expenseService
            .approve(id, payload)
            .then(({ data }) => {
              if (!data.error) {
                this.fetchItems();
              }
            })
            .catch(e => {
              this.$helpers.showNotification(e.message);
            })
            .finally(() => {
              this.processing = false;
              this.processedId = null;
            });
        }
      },
      onClickImprestApproval({ id, imprest }, status) {
        if (id) {
          this.processedId = id;
          this.isApproveProcess = status;
          const payload = {
            approved: status,
            note: "",
            approvedAmount: imprest.requested.amount
          };
          this.processing = true;
          this.$api.imprestService
            .approve(id, payload)
            .then(({ data }) => {
              if (!data.error) {
                this.fetchItems();
              }
            })
            .catch(e => {
              this.$helpers.showNotification(e.message);
            })
            .finally(() => {
              this.processing = false;
              this.processedId = null;
            });
        }
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped lang="scss">
  .request-table::v-deep {
    .v-data-table__wrapper {
      min-height: 320px;
    }
  }
</style>
